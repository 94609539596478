<!-- 随机点名 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <div class="title_css">随机点名</div>
      <div v-if="numberPeopleShow">
        <div class="numberPeople_box_css">
          <div
            @click="clickPeople('-')"
            :class="{ grey_Css: numberPeople <= 1 }"
          >
            <i class="el-icon-minus"></i>
          </div>
          <div>{{ numberPeople }}</div>
          <div
            @click="clickPeople('+')"
            :class="{ grey_Css: numberPeople >= allList.length }"
          >
            <i class="el-icon-plus"></i>
          </div>
        </div>
        <div class="rollCall_bottom_css">
          <div @click="clickSelect()">开始抽选</div>
            <div>
                <el-checkbox border v-model="checkedLeave">不抽选请假人员</el-checkbox>
            </div>
        </div>
      </div>
      <div v-if="randomShow" class="random_css">
        <avatar :imagesrc="studentItem.photo" fontSize="13" style="margin-bottom: 1.8rem;" />
        <div class="random_name_css">{{ studentItem.userName }}</div>
        <div class="random_zc_css">正在随机抽选...</div>
      </div>
      <div v-if="rollCallShow">
        <div class="rollCall_item_css">
          <div v-for="(item, index) in studentSelect" :key="index">
            <avatar :imagesrc="item.photo" fontSize="6" style="margin-bottom: 5px;" />
            <div>{{ item.userName }}</div>
          </div>
        </div>
        <div class="rollCall_bottom_css">
          <div @click="clickAnew()">重新抽选</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  GetClassUserList,
  getClassLeaveUser,
  getNoRollCallUser,
  rollCallRecordSave
} from "@/api/index.js";
import moment from "moment";
let setIn = null
export default {
  data() {
    return {
      classID: localStorage.getItem('classID'),
      numberPeople: 1,
      checkedLeave: false, // 不抽选请假人员
      rollCallShow: false,
      numberPeopleShow: true,
      randomShow: false,
      studentSelect: [], // 抽选结果
      studentItem: {
        name: "",
        portrait: "",
      }, // 随机时的展示
      list: [], // 全部学生
      allList: [], // 暂存学生
      LeaveList: [], // 请假人员
      callUserList: [], // 未被抽选的学生
      studentList: [],
    };
  },
  created(){
    this.getClassList(); // 班级学生成员
    this.getNoRollCallUserList(); // 未被点名学生
  },
  methods: {
    // 获取人员列表
    getClassList() {
      if (this.classID && this.classID != "0") {
        GetClassUserList({
          ClassID: this.classID,
          rType: 1,
        }).then((res) => {
          this.list = res.data;
          this.allList = JSON.parse(JSON.stringify(res.data));
          this.studentList = JSON.parse(JSON.stringify(this.allList))
          this.getClassLeaveUserList();
        });
      }
    },
    // 获取未被点名学生
    getNoRollCallUserList(){
      getNoRollCallUser({
          ClassID: this.classID,
          StartTime:moment().startOf('months').format('YYYY-MM-DD'),
          EndTime: moment().format('YYYY-MM-DD')
        }).then((res) => {
          this.callUserList = res.data;
        });
    },
    // 获取请假人员列表
    getClassLeaveUserList() {
      if (this.classID && this.classID != "0") {
        getClassLeaveUser({
          ClassID: this.classID,
        }).then((res) => {
          this.LeaveList = res.data;
          this.getShuJu();
        });
      }
    },
    // 处理列表数据
    getShuJu() {
      for (let i = 0; i < this.LeaveList.length; i++) {
        for (let j = this.list.length - 1; j >= 0; j--) {
          if (this.LeaveList[i].UserID == this.list[j].UserID) {
            this.list.splice(j, 1);
          }
        }
      }
    },
    // 修改人数
    clickPeople(e) {
      if (e == "+") {
        if (this.numberPeople < this.studentList.length) {
          this.numberPeople++;
        }
      } else if (e == "-") {
        if (this.numberPeople > 1) {
          this.numberPeople--;
        }
      }
    },
    // 重新抽选
    clickAnew() {
      this.numberPeopleShow = true;
      this.randomShow = false;
      this.rollCallShow = false;
    },
    // 开始抽选按钮
    clickSelect() {
      if(this.checkedLeave){
        this.studentList = this.list
      }else{
        this.studentList = this.allList
      }
      if(this.numberPeople > this.studentList.length){
        this.$message.error('抽选人数大于待抽选学生，请减少抽选人数')
        return
      }

      // if(this.checkedLeave){
      //   let ncList = JSON.parse(JSON.stringify(this.callUserList))
      //   for (let i = 0; i < this.LeaveList.length; i++) {
      //     for (let j = ncList.length - 1; j >= 0; j--) {
      //       if (this.LeaveList[i].UserID == ncList[j].UserID) {
      //         ncList.splice(j, 1);
      //       }
      //     }
      //   }
      //   if(ncList.length < 20){
      //     this.studentList = this.studentList.concat(ncList)
      //   }
      // }else{
      //   if(this.callUserList.length < 20){
      //     this.studentList = this.studentList.concat(this.callUserList)
      //   }
      // }
      if(this.callUserList.length < 20){
        this.studentList = this.studentList.concat(this.callUserList)
      }
      
      this.numberPeopleShow = false;
      this.randomShow = true;
      let num = 0;
      if(setIn){
        clearInterval(setIn);
        setIn = null
      }
      this.studentSelect = []
      // setTimeout(()=>{
      //   this.studentSelect = this.getRandomArr(
      //     this.studentList,
      //     this.numberPeople
      //   );
      // },0)

      let lists = JSON.parse(JSON.stringify(this.studentList))
      
      // 使用异步，在抽的同时处理数据，否则可能会等一会才出抽奖动画
      setTimeout(()=>{
        for(let i=0;i<this.numberPeople;i++){
          let ab = this.getRandomArr()
          this.studentSelect.push(ab)
        }
      },0)

      setIn = setInterval(() => {
        let anum = Math.floor(Math.random() * lists.length);
        this.studentItem = lists[anum];
        num++;
        if (num > 20) {
          clearInterval(setIn);
          this.randomShow = false;
          this.rollCallShow = true;
          num = 0;
          this.getRollCallRecordSave()
        }
      }, 100);
    },
    getRollCallRecordSave(){
      let arrl = []
      this.studentSelect.map(val =>{
        arrl.push(val.UserID)
      })
      rollCallRecordSave({
        classID: this.classID,
        studentID: arrl.toString(),
        rcScore:'',
        temporary:''
      }).then((res) => {
        this.getNoRollCallUserList();
      });
    },
    //随机抽取
    // getRandomArr(arr, count) {
    //   var shuffled = arr.slice(0),
    //     i = arr.length,
    //     min = i - count,
    //     temp,
    //     index;
    //   while (i-- > min) {
    //     //打乱数组
    //     index = Math.floor((i + 1) * Math.random());
    //     temp = shuffled[index];
    //     shuffled[index] = shuffled[i];
    //     shuffled[i] = temp;
    //   }
    //   function unique(arr){
    //     const res= new Map()
    //     return arr.filter((a)=> !res.has(a.UserID) && res.set(a.UserID,1))
    //   }
    //   let arf = shuffled.slice(min)
    //   let bbc = unique(arf).length
    //   if(bbc != count){
    //     return this.getRandomArr(arr, count)
    //   } else {
    //     return arf
    //   }
    // },
    //随机抽取
    getRandomArr() {
      let anum = Math.floor(Math.random() * this.studentList.length)
      let obj = JSON.parse(JSON.stringify(this.studentList[anum]))
      for (let j = this.studentList.length - 1; j >= 0; j--) {
        if (obj.UserID == this.studentList[j].UserID) {
          this.studentList.splice(j, 1);
        }
      }
      return obj
    },
  },
};
</script>
<style scoped>
.box_css {
  width: 1200px;
  height: calc(100vh - 230px);
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
}
.title_css {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0;
}
.numberPeople_box_css {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.numberPeople_box_css > div:nth-child(odd) {
  width: 80px;
  height: 80px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef7fe;
}
.numberPeople_box_css > div:nth-child(odd) > i {
  font-size: 32px;
  color: #5a94e6;
  font-weight: bold;
}
.grey_Css {
  background-color: #f8f8f8 !important;
}
.grey_Css > i {
  color: #c7c7c7 !important;
}
.numberPeople_box_css > div:nth-child(2) {
  font-size: 100px;
  min-width: 250px;
  padding: 0 15px;
  text-align: center;
}
.rollCall_bottom_css {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  position: relative;
}
.rollCall_bottom_css > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 450px;
  background-color: #59a6f6;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
}
.rollCall_bottom_css > div:nth-child(2) {
    position: absolute;
    right: 50px;
}
/deep/ .el-checkbox__label{
    font-size: 18px !important;
}
/deep/ .el-checkbox__inner{
    width: 18px;
    height: 18px;
}
/deep/ .el-checkbox__inner::after{
    width: 5px;
    height: 10px;
    left: 5px;
}
.random_css {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 480px;
}
.random_name_css {
  font-size: 35px;
  font-weight: bold;
}
.random_zc_css {
  font-size: 20px;
  color: #c7c7c7;
  margin-top: 25px;
}
.rollCall_item_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow-y: auto;
}
.rollCall_item_css > div {
  margin: 10px;
  font-size: 24px;
  text-align: center;
}
</style>